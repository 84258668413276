import styled from '@emotion/styled';
import React from 'react';
import { ContentfulImageHeadingAndLongText } from '../../graphql-types';
import { css } from '@emotion/core';
import TopSectionImage from './TopSectionImage';
import presets from '../../styles/presets';
import { FullWidthSection, ContentWrapper } from '../common/layout';
import { OpenSansParagraph, RecoletaHeading } from '../common/typography';
import { ButtonLink } from '../common/Button';
import { bookDoctorLink } from '../../utils/constants';
import { useIntl } from 'react-intl';

const TextWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '18px',
  paddingRight: '18px',
  marginTop: '30px',
  textAlign: 'center',
  [presets.md]: {
    width: '450px',
    marginTop: '100px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  [presets.lg]: {
    textAlign: 'left',
    marginTop: '-100px',
    marginLeft: '0',
    marginRight: '0',
  },
  [presets.xl]: {
    marginLeft: '64px',
  },
});

const sectionStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [presets.lg]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const headingStyle = css({
  fontSize: '28px',
  lineHeight: '32px',
  [presets.md]: {
    fontSize: '40px',
    lineHeight: '44px',
  },
});

const heroParagraphStyle = css({
  marginTop: '11px',
  fontSize: '14px',
  lineHeight: '20px',
  [presets.md]: {
    fontSize: '18px',
    lineHeight: '26px',
  },
});

const scheduleButtonStyle = css({
  marginTop: '20px',
});

const TopSection: React.FC<ContentfulImageHeadingAndLongText> = ({
  heading,
  text,
  image,
}) => {
  const intl = useIntl();

  return (
    <FullWidthSection>
      <ContentWrapper css={sectionStyle}>
        <TextWrapper>
          <RecoletaHeading css={headingStyle}>{heading}</RecoletaHeading>
          <OpenSansParagraph css={heroParagraphStyle}>
            {text.childMarkdownRemark.rawMarkdownBody}
          </OpenSansParagraph>
          <div>
            <ButtonLink css={scheduleButtonStyle} to={bookDoctorLink}>
              {intl.formatMessage({
                id: 'doctors.schedule.booking',
                defaultMessage: 'Informationsgespräch buchen',
              })}
            </ButtonLink>
          </div>
        </TextWrapper>
        <TopSectionImage image={image.fluid} />
      </ContentWrapper>
    </FullWidthSection>
  );
};

export default TopSection;
