import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import presets from '../../styles/presets';
import {
  OpenSansParagraph,
  MonoFontLabel,
  RecoletaHeading,
} from './typography';
import { markdownText } from '../../pages/doctors';

interface Props {
  labelText?: string;
  headingText: string;
  paragraphText: string;
}

const TripleContainer = styled.div(css`
  display: flex;
  flex-direction: column;
  text-align: center;
`);

const labelStyle = css({
  marginBottom: '7px',
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '1.5px',
  fontWeight: 700,
  [presets.lg]: {
    lineHeight: '19px',
  },
});

const headingStyle = css({
  marginBottom: '7px',
  fontSize: '24px',
  lineHeight: '32px',
  fontWeight: 500,

  [presets.md]: {
    fontSize: '40px',
    lineHeight: '38px',
  },
  [presets.lg]: {
    fontSize: '40px',
    lineHeight: '44px',
  },
});

const paragraphStyle = css({
  fontSize: '14px',
  lineHeight: '20px',
  opacity: '70%',
  [presets.md]: {
    fontSize: '16px',
    lineHeight: '23px',
  },
  [presets.lg]: {
    fontSize: '18px',
    lineHeight: '26px',
    opacity: '80%',
  },
});

const TripleTitle: React.FC<Props> = ({
  labelText,
  headingText,
  paragraphText,
}) => (
  <TripleContainer>
    <MonoFontLabel css={labelStyle}>{labelText}</MonoFontLabel>
    <RecoletaHeading css={headingStyle}>{headingText}</RecoletaHeading>
    <OpenSansParagraph css={paragraphStyle}>
      {markdownText(paragraphText)}
    </OpenSansParagraph>
  </TripleContainer>
);

export default TripleTitle;
