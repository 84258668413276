import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import { ContentfulImageHeadingAndShortText } from '../../graphql-types';
import { ContentWrapper, FullWidthSection } from '../common/layout';
import { OpenSansParagraph, RecoletaHeading } from '../common/typography';
import TripleTitle from '../common/TripleTitle';
import presets from '../../styles/presets';

interface Props {
  header: ContentfulImageHeadingAndShortText;
  cards: ContentfulImageHeadingAndShortText[];
}

const sectionStyle = css`
  margin-top: 200px;
`;

const wrapperStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;

  ${presets.xl} {
    padding-left: 100px;
    padding-right: 100px;
  }
`;

const TitleContainer = styled.div(css`
  max-width: 532px;
`);

const BoxesContainer = styled.div(css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  ${presets.md} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
`);

const OutcomeBox = styled.div(css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 12px;
  width: 100%;
  padding: 30px 15px;
  justify-content: space-evenly;
  flex-basis: 140px;
  flex-grow: 1;
  background: #ffffff;
  box-shadow: 0px -4px 6px rgba(22, 11, 44, 0.04),
    0px 4px 6px rgba(22, 11, 44, 0.04);
  border-radius: 10px;

  ${presets.md} {
    width: 48%;
    flex-basis: auto;
    flex-grow: 0;
    align-self: stretch;
    /* height: 150px; */
  }

  ${presets.lg} {
    width: 30%;
  }

  ${presets.xl} {
    padding: 20px;
  }
`);

const BoxTextContainer = styled.div(css`
  width: 66.6%;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 10px;
`);

const boxHeadingStyle = css`
  font-size: 18px;
  line-height: 24px;
`;

const boxParagraphStyle = css`
  font-size: 12px;
  line-height: 16px;
  opacity: 0.8;
  margin-top: 10px;
`;

const Advantages: React.FC<Props> = ({ header, cards }) => {
  return (
    <FullWidthSection css={sectionStyle}>
      <ContentWrapper css={wrapperStyle}>
        <TitleContainer>
          <TripleTitle
            labelText={header.prefix}
            headingText={header.heading}
            paragraphText={header.shortText}
          />
        </TitleContainer>
        <BoxesContainer>
          {cards.map((oc, ind) => (
            <OutcomeBox key={ind}>
              <img
                src={oc.image.file.url}
                alt={oc.image.title}
                // css={css`
                //   margin-top: auto;
                //   margin-bottom: auto;
                // `}
              />
              <BoxTextContainer>
                <RecoletaHeading css={boxHeadingStyle}>
                  {oc.heading}
                </RecoletaHeading>
                <OpenSansParagraph css={boxParagraphStyle}>
                  {oc.shortText}
                </OpenSansParagraph>
              </BoxTextContainer>
            </OutcomeBox>
          ))}
        </BoxesContainer>
      </ContentWrapper>
    </FullWidthSection>
  );
};

export default Advantages;
