import React from 'react';
import { ButtonLink } from '../../common/Button';
import { SYSTEM_FONTS } from '../../../styles/typography';
import { ReactComponent as Sparks } from '../../../assets/svgs/homepage/sparks.svg';
import { ReactComponent as LastCta } from '../../../assets/svgs/homepage/last-cta.svg';
import presets from '../../../styles/presets';
import { useIntl } from 'react-intl';
import { typeformEligiblityCheckerLink } from '../../../utils/constants';
import Container from '../Container';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CallToActionSection = ({ callToAction }: any) => {
  const intl = useIntl();

  return (
    <Container
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        padding: '2rem 0',
        [presets.md]: {
          padding: '4rem 0',
        },
      }}
    >
      <LastCta
        css={{
          margin: '1rem',
        }}
      />
      <h1
        css={{
          fontFamily: ['Recoleta Alt'].concat(SYSTEM_FONTS).join(', '),
          fontWeight: 500,
          marginBottom: '1rem',
          color: '#160B2C',
        }}
      >
        {callToAction.title
          ? callToAction.title
          : intl.formatMessage({
              id: 'homepage.v2.last.cta.title',
              defaultMessage: 'Worauf wartest du noch?',
            })}
      </h1>
      <p
        css={{
          fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
          fontWeight: 500,
          fontSize: 16,
          maxWidth: 400,
          color: '#000000',
        }}
      >
        {callToAction.text
          ? callToAction.text
          : intl.formatMessage({
              id: 'homepage.v2.last.cta.text',
              defaultMessage:
                'Wir begleiten dich Schritt für Schritt auf deinem Weg zu spürbar weniger Reizdarm-Beschwerden',
            })}
      </p>

      <div
        style={{
          position: 'relative',
          display: 'inline-block',
          marginBottom: '3rem',
        }}
      >
        {callToAction.button ? (
          callToAction.button
        ) : (
          <ButtonLink to={typeformEligiblityCheckerLink}>
            {intl.formatMessage({
              id: 'homepage.problems.cta.text',
              defaultMessage: 'Kostenfreie Therapie Starten',
            })}
          </ButtonLink>
        )}

        <div
          css={{
            position: 'absolute',
            top: 45,
            left: -45,
            zIndex: 1,
            display: 'none',

            [presets.md]: {
              display: 'block',
            },
          }}
        >
          <Sparks />
        </div>
      </div>
    </Container>
  );
};

export default CallToActionSection;
