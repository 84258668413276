import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import BackgroundImage from '../../assets/images/doctors/care_principles_background.svg';
import BackgroundImageMobile from '../../assets/images/doctors/care_principles_background_mobile.svg';
import Img from 'gatsby-image';

import {
  ContentfulImageHeadingAndLongText,
  ContentfulImageHeadingAndShortText,
  ContentfulLink,
} from '../../graphql-types';
import presets from '../../styles/presets';
import { ContentWrapper, FullWidthSection } from '../common/layout';
import TripleTitle from '../common/TripleTitle';
import MarkdownRenderer from '../common/MarkdownRenderer';
import { ButtonLink } from '../common/Button';
import { RecoletaHeading } from '../common/typography';

interface Props {
  header: ContentfulImageHeadingAndShortText;
  link: ContentfulLink;
  principlesList: ContentfulImageHeadingAndLongText[];
}

const sectionStyle = css`
  background: url(${BackgroundImageMobile});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 10% 0;
  padding-top: 80px;
  padding-bottom: 80px;
  margin-top: 70px;
  margin-bottom: 30px;

  ${presets.md} {
    background: url(${BackgroundImage});
    background-size: 1860px 100%;
    background-position: 50% 0;
    background-repeat: no-repeat;
    margin-top: 120px;
    padding-top: 100px;
  }
`;

const wrapperStyle = css(`
  display: flex;
  flex-direction: column;
  padding-left: 45px;
  padding-right: 45px;

  ${presets.md} {
    flex-direction: row-reverse;
    justify-content: space-around;
    padding-left: 80px;
    padding-right: 80px;
  }

  ${presets.xl} {
    padding-left: 150px;
    padding-right: 150px;
  }
`);

const PrinciplesBox = styled.div(
  css(`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 60px;

  ${presets.md} {
    max-width: 50%;
    align-items: flex-start;
    align-self: center;
    div div,span {
      text-align: left;
    }
  }
`)
);

const Screenshot = css`
  /* margin: 0 auto; */
  width: 100%;
  border-radius: 20px;
  border: 4px solid rgba(22, 11, 44, 1);

  ${presets.md} {
    align-self: center;
    width: 250px;
    height: 444px;
  }

  ${presets.lg} {
    width: 310px;
    height: 551px;
  }
`;

const Principle = styled.div(css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`);

const PrinciplesContainer = styled.div(css`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 30px;
`);

const Principles: React.FC<Props> = ({ header, link, principlesList }) => {
  return (
    <FullWidthSection css={sectionStyle}>
      <ContentWrapper css={wrapperStyle}>
        <PrinciplesBox>
          <TripleTitle
            headingText={header.heading}
            paragraphText={header.shortText}
            labelText={header.prefix}
          ></TripleTitle>
          <PrinciplesContainer>
            {principlesList.map((pr) => (
              <Principle key={pr.text.childMarkdownRemark.rawMarkdownBody}>
                <img
                  src={pr.image.file.url}
                  alt={pr.image.description}
                  css={{ marginRight: '10px', marginTop: '5px' }}
                />
                <RecoletaHeading>
                  <MarkdownRenderer {...pr.text.childMarkdownRemark} />
                </RecoletaHeading>
              </Principle>
            ))}
          </PrinciplesContainer>
          {link && <ButtonLink to={link.url}>{link.text}</ButtonLink>}
        </PrinciplesBox>

        <Img fluid={header.image.fluid} css={Screenshot}></Img>
      </ContentWrapper>
    </FullWidthSection>
  );
};

export default Principles;
