import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React, { useRef } from 'react';
import Slider, { Settings } from 'react-slick';
import {
  ContentfulImageHeadingAndShortText,
  ContentfulImageAndText,
} from '../../../graphql-types';
import { ContentWrapper } from '../layout';
import MarkdownRenderer from '../MarkdownRenderer';
import Img from 'gatsby-image';
import presets from '../../../styles/presets';
import {
  OpenSansParagraph,
  MonoFontLabel,
  RecoletaHeading,
} from '../typography';
import { SYSTEM_FONTS } from '../../../styles/typography';

interface Props {
  header: ContentfulImageHeadingAndShortText;
  testimonials: ContentfulImageAndText[];
}

const contentWrapperStyle = css`
  ${presets.lg} {
    padding-left: 100px;
    padding-right: 100px;
    margin-bottom: -1px;
    width: 70%;
  }
`;

const sliderWrapperStyle = css`
  padding-left: 25px;
  padding-right: 25px;
`;

const TestimonialSection = styled.div(css`
  flex-direction: column;
  margin-top: 30px;

  ${presets.md} {
    flex-direction: row;
    align-items: center;
    padding-top: 30px;
  }
`);

const TestimonialText = styled(OpenSansParagraph)(
  css({
    marginBottom: '30px',

    lineHeight: '24px',
    textAlign: 'center',

    h3: {
      marginTop: '25px',
      fontFamily: ['Recoleta Alt'].concat(SYSTEM_FONTS).join(', '),
      fontWeight: 700,
      fontSize: 24,
      color: '#000000',
    },

    p: {
      color: '#000000',
      fontSize: 16,
      fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
      fontWeight: 400,
      lineHeight: '160%',

      [presets.lg]: { fontSize: '1rem' },
    },

    [presets.md]: {
      marginBottom: '40px',
      fontSize: '20px',
      lineHeight: '28px',
    },
  })
);
const TestimonialImage = styled.div(
  css({
    flexBasis: '45%',
    flexGrow: 1,
  })
);

const headingStyle = css({
  marginBottom: '7px',
  fontSize: '24px',
  lineHeight: '32px',
  fontWeight: 500,

  [presets.md]: {
    fontSize: '40px',
    lineHeight: '38px',
  },
  [presets.lg]: {
    fontSize: '40px',
    lineHeight: '44px',
  },
});

const labelStyle = css({
  marginBottom: '7px',
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '1.5px',
  fontWeight: 700,
  [presets.lg]: {
    lineHeight: '19px',
  },
});

const sliderSettings: Settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 1000,
  slidesToShow: 2,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
      },
    },
  ],
};

const Card: React.FC<{
  image: ContentfulImageAndText['image']['fluid'];
  text: ContentfulImageAndText['text']['childMarkdownRemark'];
}> = ({ image, text }) => (
  <TestimonialSection>
    <TestimonialImage>
      <Img
        fluid={image}
        css={css`
          object-fit: scale-down;
          max-width: 300px;
          object-position: 50% 50%;
          margin: 0 auto;
        `}
      />
    </TestimonialImage>
    <TestimonialText>
      <MarkdownRenderer {...text} />
    </TestimonialText>
  </TestimonialSection>
);

const DotsTestimonial: React.FC<Props> = ({ header, testimonials }) => {
  const ref = useRef<Slider>(null);
  return (
    <ContentWrapper css={contentWrapperStyle}>
      <MonoFontLabel css={labelStyle}>{header.prefix}</MonoFontLabel>
      <RecoletaHeading css={headingStyle}>{header.heading}</RecoletaHeading>
      <Slider {...sliderSettings} ref={ref}>
        {testimonials.map((t) => (
          <div key={t.text.childMarkdownRemark.id} css={sliderWrapperStyle}>
            <div key={t.id}>
              <Card
                image={t?.image?.fluid}
                text={t?.text?.childMarkdownRemark}
              />
            </div>
          </div>
        ))}
      </Slider>
    </ContentWrapper>
  );
};

export default DotsTestimonial;
