import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { MarkdownRemark } from '../../graphql-types';
import presets from '../../styles/presets';
import MarkdownRenderer from '../common/MarkdownRenderer';
import { OpenSansParagraph, RecoletaHeading } from '../common/typography';

interface Props {
  title: string;
  text: MarkdownRemark;
  color: string;
}

const StyledBox = styled.div(
  (props) => css`
    padding: 23px 20px 23px 20px;
    width: 100%;
    background: ${props.color};
    border-radius: 8px;
    margin-top: 12px;

    ${presets.md} {
      width: 28%;
      margin-right: 12px;
      margin-left: 12px;
      margin-top: 25px;
      padding: 27px 30px 33px 30px;
    }

    .boxTitle {
      font-size: 24px;
    }

    p,
    ul {
      margin-top: 10px;
      margin-bottom: 0px;
      font-size: 14px;
      line-height: 20px;

      li {
        padding: 0px 0px;
        margin: 0px;
      }
    }
  `
);

const WhatIsCaraCareBox: FC<Props> = ({ title, text: markdown, color }) => {
  return (
    <StyledBox color={color}>
      <RecoletaHeading className="boxTitle">{title}</RecoletaHeading>
      <OpenSansParagraph>
        <MarkdownRenderer {...markdown} />
      </OpenSansParagraph>
    </StyledBox>
  );
};

export default WhatIsCaraCareBox;
