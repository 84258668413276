import { css } from '@emotion/core';
import React from 'react';
import { ContentfulHeadingAndText } from '../../../graphql-types';
import CollapseBoxWithMessage from '../CollapseBoxWithMessage';
import { ContentWrapper } from '../layout';
import presets from '../../../styles/presets';

interface Props {
  block: ContentfulHeadingAndText;
  collapsedMessage: string;
}

const wrapperStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 800px;

  padding-left: 10px;
  padding-right: 10px;

  ${presets.md} {
    padding-left: 25px;
    padding-right: 25px;
  }

  ${presets.lg} {
    padding-right: 0;
    padding-left: 0;
  }
`;

const References: React.FC<Props> = ({ block, collapsedMessage }) => {
  return (
    <ContentWrapper css={wrapperStyle}>
      <CollapseBoxWithMessage
        block={block}
        collapsedMessage={collapsedMessage}
        color="#E2E8F7"
        opacity={0.6}
      />
    </ContentWrapper>
  );
};

export default References;
