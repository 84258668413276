import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';

import presets from '../../styles/presets';
import {
  OpenSansParagraph,
  RecoletaHeading,
  MonoFontLabel,
} from '../common/typography';
import { ContainerProps } from 'emotion-flex';
import { markdownText } from '../../pages/doctors';
import TypeformEmbedButton from '../Homepage/2023wk35/TypeformOverlay';

interface ItemProps {
  icon: string;
  number: string;
  description: string | JSX.Element;
}

interface Props {
  prefix: string;
  title: string;
  items: ItemProps[];
  primaryCTAButtonText: string;
}

const CardsContainer = styled.div(css`
  display: flex;
  flex-direction: column;

  ${presets.md} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 50px;
    margin-right: 50px;
  }

  ${presets.lg} {
    margin-left: 100px;
    margin-right: 100px;
  }
`);

const PrescriptionCard = styled.div(
  css(`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  ${presets.md} {
    width: 50%;
    flex-direction: row;
    div div, span {
      text-align: left
    }
  }

  ${presets.lg}  {
    width: 45%;
  }
`)
);

const imageStyle = css`
  width: 120px;
  margin-bottom: 1rem;

  ${presets.md} {
    margin-right: 10px;
  }
`;

const CardTextContainer = styled.div(`
  display: flex;
  flex-direction: column;
  align-items: center;

   ${presets.md} {
    text-align: left;
    align-items: flex-start;
  }
`);

const CardTitle = styled(RecoletaHeading)(`
  font-size: 28px;
  margin-top: 0px;
`);

const CardText = styled(OpenSansParagraph)(`
  font-size: 15px;
  text-align: center;
`);

const Container = styled('div')(
  {
    paddingLeft: 24,
    paddingRight: 24,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  ({ fluid }: ContainerProps) => ({
    maxWidth: fluid ? '100%' : '1000px',
  })
);

const labelStyle = css({
  marginBottom: '7px',
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '1.5px',
  fontWeight: 700,
  [presets.lg]: {
    lineHeight: '19px',
  },
});

const headingStyle = css({
  marginBottom: '7px',
  fontSize: '24px',
  lineHeight: '32px',
  fontWeight: 500,

  [presets.md]: {
    fontSize: '40px',
    lineHeight: '38px',
  },
  [presets.lg]: {
    fontSize: '40px',
    lineHeight: '44px',
  },
});

const HowToPrescribe: React.FC<Props> = ({
  prefix,
  title,
  items,
  primaryCTAButtonText,
}) => {
  return (
    <Container
      css={
        presets.sm && {
          marginTop: '4rem',
        }
      }
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '0 1.5rem',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <MonoFontLabel css={labelStyle}>{prefix}</MonoFontLabel>
        <RecoletaHeading css={headingStyle}>
          {markdownText(title)}
        </RecoletaHeading>
      </div>

      <CardsContainer>
        {items.map((item, i) => (
          <PrescriptionCard key={i}>
            <img src={item.icon} css={imageStyle} alt={`Prescription ${i}`} />
            <CardTextContainer>
              <CardTitle>{item.number}</CardTitle>
              <CardText>
                {typeof item.description === 'string'
                  ? markdownText(item.description)
                  : item.description}
              </CardText>
            </CardTextContainer>
          </PrescriptionCard>
        ))}
      </CardsContainer>

      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '0 1.5rem',
          width: '100%',
          textAlign: 'center',
          marginTop: '1rem',
        }}
      >
        <TypeformEmbedButton
          index={3}
          typeformId="W5grl7sJ"
          buttonText={primaryCTAButtonText}
          buttonStyle={{
            padding: '1rem 1rem',
            backgroundColor: '#489F9D',
            color: '#ffffff',
            fontWeight: 700,
          }}
        />
      </div>
    </Container>
  );
};

export default HowToPrescribe;
