import React from 'react';
import {
  ContentfulImageHeadingAndShortText,
  ContentfulLink,
  MarkdownRemark,
} from '../../graphql-types';
import { ContentWrapper, FullWidthSection } from '../common/layout';
import BackgroundImage from '../../assets/images/doctors/request_flyer_background.svg';
import BackgroundImageMobile from '../../assets/images/doctors/request_flyer_background_mobile.svg';
import { css } from '@emotion/core';
import presets from '../../styles/presets';
import TripleTitle from '../common/TripleTitle';
import { ButtonLink } from '../common/Button';
import { OpenSansParagraph } from '../common/typography';
import MarkdownRenderer from '../common/MarkdownRenderer';
import { useIntl } from 'react-intl';

interface Props {
  header: ContentfulImageHeadingAndShortText;
  buttons: ContentfulLink[];
  agreementText: MarkdownRemark;
}

const sectionStyle = css`
  background: url(${BackgroundImageMobile});
  background-size: 100% calc(100% + 10vw);
  background-clip: border-box;
  background-repeat: no-repeat;
  background-position: 0 0;
  padding: 90px 0 120px 0;
  margin-top: 80px;
  z-index: 10;
  position: relative;

  ${presets.md} {
    background: url(${BackgroundImage});
    background-size: 2100px 100%;
    background-position: 50% 0;
    background-repeat: no-repeat;
    padding-top: 200px;
    margin-top: 10px;
  }
`;

const wrapperStyle = css(`
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  padding-right: 40px;

  div div,span {
    color: white;
  }

  ${presets.md} {
    padding-left: 80px;
    padding-right: 80px;
    max-width: 500px
  }

  ${presets.xl} {
    padding-left: 150px;
    padding-right: 150px;
    max-width: 700px;
  }
`);

const agreementStyle = css`
  color: white;
  font-size: 12px;
  margin: 50px auto 0px auto;
  text-align: center;

  & a {
    color: white;
    text-decoration: underline;
  }
`;

const Flyers: React.FC<Props> = ({ header, buttons, agreementText }) => {
  const intl = useIntl();

  return (
    <FullWidthSection css={sectionStyle}>
      <ContentWrapper css={wrapperStyle}>
        <TripleTitle
          headingText={header.heading}
          paragraphText={header.shortText}
          labelText={header.prefix}
        />
        <ButtonLink
          to={buttons[0].url}
          css={css`
            margin-top: 45px;
          `}
        >
          {buttons[0].text}
        </ButtonLink>
        {intl.locale === 'de' ? null : (
          <ButtonLink
            to={buttons[1].url}
            design="transparent"
            css={css`
              margin-top: 20px;
            `}
          >
            {buttons[1].text}
          </ButtonLink>
        )}
        <OpenSansParagraph css={agreementStyle}>
          <MarkdownRenderer {...agreementText} />
        </OpenSansParagraph>
      </ContentWrapper>
    </FullWidthSection>
  );
};

export default Flyers;
