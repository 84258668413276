import styled from '@emotion/styled';
import hexToRgba from 'hex-to-rgba';
import React, { useState } from 'react';
import { ContentfulHeadingAndText } from '../../graphql-types';
import presets from '../../styles/presets';
import MarkdownRenderer from './MarkdownRenderer';
import { OpenSansParagraph, RecoletaHeading } from './typography';
import ArrowIcon from '../../assets/images/arrow-icon.svg';
import { css } from '@emotion/core';

interface ArrowImageProps {
  isActive: boolean;
}
interface ItemBoxProps {
  color: string;
  opacity: number;
}

const ItemBox = styled.div<ItemBoxProps>`
  transition: 0.3s ease-in-out;
  padding: 24px 19px;
  display: flex;
  flex-direction: column;
  background-color: ${({ color, opacity }) => hexToRgba(color, opacity)};
  border-radius: 10px;

  ${presets.md} {
    padding: 50px 25px 45px 32px;
  }
`;

const ArrowImage = styled.img<ArrowImageProps>`
  margin-bottom: 0;
  margin-left: 7px;
  transition: 0.25s ease-in-out;
  max-width: 16px;
  transform: ${({ isActive }) => (!isActive ? 'rotate(0)' : 'rotate(180deg)')};
  &:hover {
    cursor: pointer;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  cursor: pointer;
`;

const headingStyle = css`
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 15px;

  ${presets.md} {
    font-size: 24px;
    line-height: 32px;
  }

  ${presets.lg} {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 25px;
  }

  ${presets.xl} {
    font-size: 32px;
    line-height: 44px;
  }
`;

const paragraphStyle = css`
  font-size: 10px;
  line-height: 18px;

  ${presets.md} {
    font-size: 13px;
    line-height: 20px;
  }

  ${presets.lg} {
    font-size: 16px;
    line-height: 24px;
  }
`;

interface Props {
  collapsedMessage: string;
  block: ContentfulHeadingAndText;
  color: string;
  opacity: number;
}

const CollapseBoxWithMessage: React.FC<Props> = ({
  collapsedMessage,
  block,
  color,
  opacity,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <ItemBox color={color} opacity={opacity}>
      <HeaderContainer onClick={() => setIsOpen(!isOpen)}>
        <RecoletaHeading css={headingStyle}>{block.heading}</RecoletaHeading>
        <ArrowImage src={ArrowIcon} alt="Arrow" isActive={isOpen} />
      </HeaderContainer>
      <OpenSansParagraph css={paragraphStyle}>
        {isOpen === true ? (
          <MarkdownRenderer {...block.text.childMarkdownRemark} />
        ) : (
          collapsedMessage
        )}
      </OpenSansParagraph>
    </ItemBox>
  );
};

export default CollapseBoxWithMessage;
