import React from 'react';
import { MarkdownRemark } from '../../graphql-types';
import Markdown from 'react-markdown/with-html';

const MarkdownRenderer = (props: MarkdownRemark) => (
  <Markdown
    source={props.rawMarkdownBody}
    allowDangerousHtml
    disallowedTypes={[]}
  />
);

export default MarkdownRenderer;
