import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import {
  ContentfulHeadingAndText,
  ContentfulImageHeadingAndShortText,
} from '../../graphql-types';
import presets from '../../styles/presets';
import { startKitLink } from '../../utils/constants';
import { ButtonLink } from '../common/Button';
import {
  ContentWrapper,
  FullWidthSection,
  StartKitWrapper,
} from '../common/layout';
import TripleTitle from '../common/TripleTitle';
import WhatIsCaraCareBox from './WhatIsCaraCareBox';

interface Props {
  heading: ContentfulImageHeadingAndShortText;
  boxes: ContentfulHeadingAndText[];
}

const BoxesContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '30px',

  [presets.md]: {
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: '65px',
  },
  [presets.lg]: {
    marginLeft: '60px',
    marginRight: '60px',
  },
});

const sectionStyle = css({
  marginTop: '80px',
  paddingLeft: '12px',
  paddingRight: '12px',
});

const starterKitButtonStyle = css({
  marginTop: '25px',
});

const WhatIsCaraCare: React.FC<Props> = ({ heading, boxes }) => {
  const intl = useIntl();

  const colors = [
    'rgba(240, 233, 243, 0.6)',
    'rgba(228, 239, 238, 0.6)',
    'rgba(254, 247, 231, 0.6)',
    'rgba(96, 139, 228, 0.3)',
    'rgba(255, 218, 213, 0.3)',
  ];
  return (
    <FullWidthSection>
      <ContentWrapper css={sectionStyle}>
        <TripleTitle
          labelText={heading.prefix}
          headingText={heading.heading}
          paragraphText={heading.shortText}
        />
        <BoxesContainer>
          {boxes.map((box, ind) => (
            <WhatIsCaraCareBox
              title={box.heading}
              text={box.text.childMarkdownRemark}
              key={box.id}
              color={colors[ind]}
            />
          ))}
        </BoxesContainer>
      </ContentWrapper>
      <StartKitWrapper>
        <ButtonLink css={starterKitButtonStyle} to={startKitLink}>
          {intl.formatMessage({
            id: 'doctors.download.starter.kit',
            defaultMessage: 'Infomaterial herunterladen',
          })}
        </ButtonLink>
      </StartKitWrapper>
    </FullWidthSection>
  );
};

export default WhatIsCaraCare;
