import React, { CSSProperties } from 'react';
import { ReactComponent as LeftArrow } from '../../../assets/images/programs-slider-left-arrow.svg';
import { ReactComponent as RightArrow } from '../../../assets/images/program-slider-right-arrow.svg';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

interface Props {
  onClick?: () => void;
  position?: 'left' | 'right';
  className?: string;
  style?: CSSProperties;
}

const ArrowContainer = styled.div(css`
  cursor: pointer;
  width: fit-content;
  user-select: none;
  z-index: 2;
`);

const SliderArrow: React.FC<Props> = ({
  onClick,
  position,
  className,
  style,
}) => {
  return (
    <ArrowContainer onClick={onClick} className={className} style={style}>
      {position === 'left' ? <LeftArrow /> : <RightArrow />}
    </ArrowContainer>
  );
};

export default SliderArrow;
