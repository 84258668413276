import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React, { FC } from 'react';

import presets from '../../styles/presets';
import { OpenSansParagraph, RecoletaHeading } from '../common/typography';
import { ContentWrapper } from '../common/layout';
import { markdownText } from '../../pages/doctors';
import Video from '../Video';

interface Item {
  image: string;
  heading: string;
  content: string;
  color: string;
}

interface Props {
  title: string;
  text: string;
  items: Item[];
}

const wrapperStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 65px auto 15px auto;
`;

const HeadingContainer = styled.div(css`
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 500px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 30px;

  ${presets.md} {
    margin-bottom: 42px;
  }
`);

const headingStyle = css({
  fontSize: '24px',
  fontWeight: 500,
  color: '#000000',

  [presets.md]: {
    fontSize: '40px',
  },
  [presets.lg]: {
    fontSize: '40px',
    lineHeight: '44px',
  },
});

const subHeadingStyle = css({
  fontSize: '24px',
  lineHeight: '32px',
  fontWeight: 600,
  padding: '0 20px',
  color: '#000000',

  [presets.md]: {
    fontSize: '24px',
    lineHeight: '38px',
  },
  [presets.lg]: {
    fontSize: '24px',
    lineHeight: '44px',
  },
});

const paragraphStyle = css({
  fontSize: '14px',
  lineHeight: '20px',
  color: '#000000',

  [presets.md]: {
    fontSize: '16px',
    lineHeight: '23px',
  },
  [presets.lg]: {
    fontSize: '16px',
    lineHeight: '26px',
  },
});

const colouredBoxStyle = (colour: string) =>
  css({
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: '10px',
    backgroundColor: colour,
    alignItems: 'center',
    height: 'fit-content',

    textAlign: 'center',
    flex: '0 0 95%',

    [presets.md]: {
      flex: '0 0 calc(50% - 6px)',
      margin: '3px',
    },

    [presets.lg]: {
      flex: '0 0 calc(30% - 6px)',
    },

    [presets.xl]: {
      flex: '0 0 calc(25% - 6px)',
    },
  });

const containerStyle = css({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '12px',
  justifyContent: 'center',
});

const BenefitsBox: FC<Item> = ({ content, image, heading, color }) => {
  return (
    <div css={colouredBoxStyle(color)}>
      <img
        style={{
          padding: '20px 0 0 0',
        }}
        src={image}
        alt={heading}
      />
      <RecoletaHeading css={subHeadingStyle}>{heading}</RecoletaHeading>
      <ul
        style={{
          textAlign: 'left',
          padding: '5px',
        }}
      >
        <OpenSansParagraph css={paragraphStyle}>
          {markdownText(content)}
        </OpenSansParagraph>
      </ul>
    </div>
  );
};

const Benefits: React.FC<Props> = ({ title, text, items }) => {
  return (
    <>
      <div
        id="video"
        css={{
          maxWidth: 730,
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: 50,
        }}
      >
        <Video
          videoSrcURL={'https://www.youtube.com/embed/Gk5KSsdN6BY'}
          videoContainerStyle={{
            paddingRight: 15,
            paddingLeft: 15,
          }}
        />
      </div>
      <ContentWrapper css={wrapperStyle}>
        <HeadingContainer>
          <RecoletaHeading css={headingStyle}>
            {markdownText(title)}
          </RecoletaHeading>
          <OpenSansParagraph css={paragraphStyle}>
            {markdownText(text)}
          </OpenSansParagraph>
        </HeadingContainer>
        <div css={containerStyle}>
          {items?.map((item: Item, i) => (
            <BenefitsBox
              key={i}
              content={item.content}
              image={item.image}
              heading={item.heading}
              color={item.color}
            />
          ))}
        </div>
      </ContentWrapper>
    </>
  );
};

export default Benefits;
