import { graphql } from 'gatsby';
import React, { useState } from 'react';
import { Link, SEO } from '../components';
import { HowToPrescribe, TestimonialsSection } from '../components/Doctors';
import { withLayout } from '../components/Layout';
import { ContentfulDoctorsPage } from '../graphql-types';
import FeatureHeader from '../components/Doctors/FeatureHeader';
import Benefits from '../components/Doctors/Benefits';
import docBenefits from '../assets/svgs/doctors/doc-benefits.svg';
import patientBenefits from '../assets/svgs/doctors/patient-benefits.svg';
import Markdown from 'react-markdown/with-html';
import { useIntl } from 'react-intl';
import StudySection from '../components/Doctors/StudySection';
import NewTracking from '../components/Doctors/NewTracking';
import BgImage1 from '../assets/svgs/product/img-bg-slideshow-features-1.svg';
import BgImage2 from '../assets/svgs/product/img-bg-slideshow-features-2.svg';
import BgImage3 from '../assets/svgs/product/img-bg-slideshow-features-3.svg';
import {
  HUBSPOT_MEETING_IBRAHIM_LINK,
  LIVE_STORM_LINK,
  WEBINAR_LINK,
} from '../utils/constants';
import CallToActionSection from '../components/Homepage/v2/CallToActionSection';
import { ReactComponent as DataSecurity } from '../assets/svgs/doctors/img_data-security.svg';
import { ReactComponent as Experts } from '../assets/svgs/doctors/img-experts.svg';
import { ReactComponent as MedicalDevice } from '../assets/svgs/doctors/img-medical-device.svg';
import { ReactComponent as Science } from '../assets/svgs/doctors/img-science.svg';
import Qualities from '../components/Doctors/Qualities';
import Prescription1 from '../assets/svgs/doctors/img-prescription-1.svg';
import Prescription2 from '../assets/svgs/doctors/img-prescription-2.svg';
import Prescription3 from '../assets/svgs/doctors/img-prescription-3.svg';
import Prescription4 from '../assets/svgs/doctors/img-prescription-4.svg';
import Webinare from '../components/Doctors/Webinare';
import {
  MonoFontLabel,
  OpenSansParagraph,
} from '../components/common/typography';
import presets from '../styles/presets';
import Hypnosis from '../assets/svgs/doctors/img-hypnosis.svg';
import Program from '../assets/svgs/doctors/img-program.svg';
import Tracking from '../assets/svgs/doctors/img-tracking.svg';
import ImageOverlay from '../components/Doctors/ImageOverlay';
import PrescriptionPreviewImage from '../assets/images/doctors/prescription-preview-image.png';
import HubspotMeetingOverlay from '../components/HubspotMeetingOverlay';
import FAQ from '../components/Product/FAQ';

interface Props {
  pathContext: {
    locale: 'en' | 'de';
  };
  data: {
    doctors: ContentfulDoctorsPage;
  };
}

export const markdownText = (text: string) => {
  return <Markdown source={text} escapeHtml={false} />;
};

const Doctors: React.FC<Props> = ({
  pathContext: { locale },
  data: { doctors },
}) => {
  const intl = useIntl();
  const [showImageOverlay, setShowImageOverlay] = useState(false);

  const toggleOverlay = () => setShowImageOverlay(!showImageOverlay);

  const EmbedAction = ({
    action,
    text,
    prefix,
    suffix,
  }: {
    action: () => void;
    text: string;
    prefix?: string;
    suffix?: string;
  }) => {
    return (
      <OpenSansParagraph
        style={{
          marginBottom: 0,
          color: '#000000',
          fontSize: '0.875rem',
          opacity: 1,
        }}
      >
        {prefix}
        <span
          onClick={action}
          style={{
            border: 'none',
            padding: '0 4px',
            margin: 0,
            textDecoration: 'underline',
            cursor: 'pointer',
            color: '#3D72DF',
            fontWeight: 700,
          }}
        >
          {text}
        </span>
        {markdownText(suffix)}
      </OpenSansParagraph>
    );
  };

  const EmbedLink = ({
    url,
    text,
    prefix,
    suffix,
  }: {
    url: string;
    text: string;
    prefix?: string;
    suffix?: string;
  }) => (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      <MonoFontLabel
        css={{
          marginBottom: 0,
          color: '#000000',
          [presets.md]: { fontSize: '0.875rem' },
        }}
      >
        {prefix}
      </MonoFontLabel>
      <Link to={url}>{text}</Link>
      <MonoFontLabel
        css={{
          marginBottom: 0,
          color: '#000000',
          [presets.md]: { fontSize: '0.875rem' },
        }}
      >
        {suffix}
      </MonoFontLabel>
    </div>
  );

  const deStrings = {
    header: {
      prefix: 'Informationen für Fachkreise',
      title: 'Die erste <b>verschreibbare digitale Reizdarm</b>-Therapie',
      items: [
        'Erweiterung Ihres Behandlungsrepertoires',
        'Budgetneutral und ohne Zuzahlung',
        <EmbedLink
          key={1}
          prefix="vom BfArM als DiGA zugelassen (→ "
          suffix=" )"
          url="#video"
          text="Was ist eine DiGA?"
        />,
      ],
      primaryCTAButtonText: 'Infomaterial anfordern',
      secondaryCTAButtonText: 'Unsere Webinare',
      secondaryCTAButtonLink: '#webinare',
    },
    study: {
      title: 'Unsere <b>Wirksamkeit</b>',
      description:
        'Wir haben Studienergebnisse veröffentlicht, die eine signifikante, klinisch relevante Symptomreduktion durch unser personalisiertes Programm belegen.',
      headlineTitle: '<b>Symptomschwere</b> (IBS-SSS)',
      headlineDescription: 'Reduktion im Vergleich zum Ausgangswert',
      itemPercentage: '70,2%',
      itemDescription:
        'der Reizdarm-Patient*innen zeigten nach 12 Wochen mit Cara Care für Reizdarm eine <b>signifikante, klinisch relevante Symptomreduktion</b> — gemessen via IBS-SSS.',
      primaryCTAButtonText: 'Weitere Infos zur Studie',
      primaryCTAButtonLink: 'https://cara.care/de/press/neuestudie',
    },
    benefits: {
      title: '<b>Vorteile</b> von Cara Care',
      description:
        'Eine Win-Win-Situation für Sie und Ihre Patient*innen dank <b><i>Cara Care für Reizdarm</i></b>.',
      items: [
        {
          image: docBenefits,
          heading: intl.formatMessage({
            id: 'benefits.column.one.content.header',
            defaultMessage: 'Vorteile für Ihre Patient*innen',
          }),
          content: intl.formatMessage({
            id: 'benefits.column.one.content',
            defaultMessage:
              '<li><b>Multimodale Reizdarm-Therapie</b>, konform mit der aktuellen S3-Leitlinie</li>\n<li><b>Personalisierte Modulzusammenstellung</b> basierend auf medizinischen Fragebögen</li>\n<li><b>zusätzliche Features</b>: Tracking und personalisierte Kochrezeptideen</li>\n<li><b>Linderung psychischer Komorbiditäten</b> (Angst, Depression), die im Praxisalltag oft nicht adressiert werden können</li>\n<li>Unterstützung bei der <b>Bewältigung krankheitsbedingter Schwierigkeiten im Alltag</b></li>\n<li><b>Vollständig erstattungsfähig</b>, ohne zusätzliche Kosten</li>',
          }),
          color: '#EAE3F3',
        },
        {
          image: patientBenefits,
          heading: intl.formatMessage({
            id: 'benefits.column.two.content.header',
            defaultMessage: 'Ihre Vorteile',
          }),
          content: intl.formatMessage({
            id: 'benefits.column.two.content',
            defaultMessage:
              '<li><b>Erweiterung Ihres Behandlungs-Repertoires</b></li>\n<li><b>Export und Vorlage von Daten zum Therapiefortschritt</b> durch Ihre Patient*innen</li>\n<li><b>Kein Mehraufwand</b>, da Patient*innen die Therapie selbstständig durchlaufen</li>\n<li><b>Budgetneutral</b> und ohne Zuzahlung</li>',
          }),
          color: '#DAECEB',
        },
      ],
    },
    tracking: {
      title: 'So funktioniert<br/><b>Cara Care für Reizdarm</b>',
      description:
        'Schritt für Schritt zur signifikanten Reduktion der Symptome und verbesserter Lebensqualität.',
      sliderTitle:
        'Unsere unterstützenden <br/><span style="color: #489F9D">Funktionen der App</span>',
      items: [
        {
          prefix: 'Tracking & Tagebuch',
          title: 'Erfassen von Ernährung, Symptomen, und Befinden.',
          description:
            'Patient*innen können Ernährung, Symptome und Gefühle erfassen und somit Zusammenhänge und Triggerfaktoren erkennen.',
          phoneImage: Tracking,
          bgImage: BgImage1,
        },
        {
          prefix: 'Individuelle Unterstützung',
          title: 'Personalisiertes Programm',
          description:
            'Ein Program, was individuell zugeschnitten ist Ihre Patient*innen folgen einem Programm, welches sich genau nach ihren Symptomen richtet.',
          phoneImage: Program,
          bgImage: BgImage2,
        },
        {
          prefix: 'Audio-geführte Hypnose',
          title: 'Darm-Hirn-Achse',
          description:
            'Audio-geführte Hypnose, Aufgaben und Quiz - Patient*innen lernen, wie der Darm und das Gehirn interagieren.',
          phoneImage: Hypnosis,
          bgImage: BgImage3,
        },
      ],
      ctaTitle: 'Neugierig geworden?',
      ctaDescription:
        'Weitere Details finden Sie auf unserer Produktseite für <b><i>Cara Care für Reizdarm</i></b>.',
      primaryCTAButtonText: 'Mehr erfahren',
      primaryCTAButtonLink: '/reizdarm',
    },
    qualities: {
      prefix: 'Qualität',
      title: 'Wir erfüllen die höchsten<br/>Standards für digitale Therapie.',
      items: [
        {
          icon: <DataSecurity />,
          title: 'Strengster Datenschutz',
          description: 'DSGVO-konform & verschlüsselte Gesundheitsdaten',
        },
        {
          icon: <Experts />,
          title: 'Wissenschaftlich fundiert',
          description: 'Entwickelt von Expertinnen und Experten',
        },
        {
          icon: <MedicalDevice />,
          title: 'Offizielles Medizinprodukt',
          description: 'Medizinprodukt der Risikoklasse 1 gemäß MDD',
        },
        {
          icon: <Science />,
          title: 'medizinisch hochaktuell',
          description: 'Basierend auf den neuesten medizinischen Leitlinien',
        },
      ],
    },
    howToPrescribe: {
      prefix: 'Cara Care für Reizdarm auf Rezept',
      title: 'Wie wird Cara Care für<br/>Reizdarm verschrieben?',
      items: [
        {
          icon: Prescription3,
          number: '1.',
          description:
            'Stellen Sie Ihren Patient*innen ein Rezept aus. Budgetneutral und ohne Zuzahlung.',
        },
        {
          icon: Prescription1,
          number: '2.',
          description:
            'Für die Verordnung muss die Diagnose Reizdarmsyndrom (ICD-10-GM: K58, K58.1, K58.2, K58.3, K58.8) vorliegen.',
        },
        {
          icon: Prescription2,
          number: '3.',
          description: (
            <EmbedAction
              prefix="Verwenden Sie "
              suffix=" und suchen Sie über die <b>PZN 17964363</b> nach <b>“CARACARE”</b>"
              action={toggleOverlay}
              text="Muster 16 (Kassenrezept)"
            />
          ),
        },
        {
          icon: Prescription4,
          number: '4.',
          description:
            'Wir unterstützen Ihre Patient*innen auf ihrem Weg zum Freischaltcode und bei allen technischen Fragen',
        },
      ],
      primaryCTAButtonText: 'Infomaterial anfordern',
    },
    webinare: {
      title: 'Unsere <b>Webinare</b>',
      description:
        'In diesen 45-minütigen Online-Fortbildungen erhalten Sie Einblicke in Cara Care für Reizdarm, die zugrundeliegenden Ansätze und Evidenz. Sie können Fragen stellen und sich mit unseren Expert:innen sowie Ihren Fachkolleg:innen austauschen.',
      items: [
        {
          title: 'Live-Webinar',
          description: '',
          primaryCTAButtonText: 'Agenda anzeigen',
          primaryCTAButtonLink: LIVE_STORM_LINK,
        },
        {
          title: 'Aufgezeichnete Webinare',
          description: '',
          primaryCTAButtonText: 'Zugang erhalten',
          primaryCTAButtonLink: WEBINAR_LINK,
        },
      ],
    },
    testimonials: {
      prefix: 'Vorstand',
      title: 'Unsere wissenschaftliche Leitung',
      items: [
        {
          image: <></>,
          title: 'Prof. Anthony Lembo',
          description:
            'Arzt und Professor der Medizin an der Harvard Medical School • Mitglied des wissenschaftlichen Beirats von Cara Care',
        },
        {
          image: <></>,
          title: 'Prof. Dr. med. Stefan Lüth',
          description:
            'Klinikdirektor und Chefarzt am Klinikum Brandenburg • Hochschulkoordinator an der Medizinischen Hochschule Brandenburg',
        },
        {
          image: <></>,
          title: 'Dr. med. Andre Sommer',
          description: 'Mitgründer von Cara Care',
        },
        {
          image: <></>,
          title: 'Linda Weißer',
          description: 'Medical Director',
        },
      ],
    },
    lastCTA: {
      title: 'Noch Fragen offen?',
      description:
        'Wir helfen im Rahmen eines 10-minütigen Informationstermins alle Unklarheiten zu klären.',
      primaryCTAButtonText: '10-minütigen Infotermin buchen',
    },
  };

  const enStrings = {
    header: {
      prefix: 'Information for professionals',
      title: 'The first <b>prescribed digital IBS</b> therapy',
      items: [
        'Expand your treatment repertoire',
        'Budget-neutral and without additional payments',
        <EmbedLink
          key={1}
          prefix="approved by BfArM as a DiGA (→ "
          suffix=" )"
          url="#video"
          text="What is a DiGA?"
        />,
      ],
      primaryCTAButtonText: 'Request informational material',
      secondaryCTAButtonText: 'Our webinars',
      secondaryCTAButtonLink: '#webinare',
    },
    study: {
      title: 'Our <b>Effectiveness</b>',
      description:
        'We have published study results that demonstrate significant, clinically relevant symptom reduction through our personalized program.',
      headlineTitle: '<b>Symptom severity</b> (IBS-SSS)',
      headlineDescription: 'Reduction compared to baseline',
      itemPercentage: '70,2%',
      itemDescription:
        'of IBS patients showed a <b>significant, clinically relevant symptom reduction</b> after 12 weeks with Cara Care for IBS — measured via IBS-SSS.',
      primaryCTAButtonText: 'More info on the study',
      primaryCTAButtonLink: 'https://cara.care/en/press/newstudy',
    },
    benefits: {
      title: '<b>Benefits</b> of Cara Care',
      description:
        'A win-win situation for you and your patients thanks to <b><i>Cara Care for IBS</i></b>.',
      items: [
        {
          image: docBenefits,
          heading: intl.formatMessage({
            id: 'benefits.column.one.content.header',
            defaultMessage: 'Benefits for your patients',
          }),
          content: intl.formatMessage({
            id: 'benefits.column.one.content',
            defaultMessage:
              '<li><b>Multimodal IBS therapy</b>, in line with the current S3 guidelines</li>\n<li><b>Personalized module compilation</b> based on medical questionnaires</li>\n<li><b>Additional features</b>: Tracking and personalized cooking recipe ideas</li>\n<li><b>Alleviation of psychological comorbidities</b> (anxiety, depression), which often cannot be addressed in everyday practice</li>\n<li>Support in <b>dealing with difficulties in everyday life caused by the disease</b></li>\n<li><b>Fully reimbursable</b>, without additional costs</li>',
          }),
          color: '#EAE3F3',
        },
        {
          image: patientBenefits,
          heading: intl.formatMessage({
            id: 'benefits.column.two.content.header',
            defaultMessage: 'Your benefits',
          }),
          content: intl.formatMessage({
            id: 'benefits.column.two.content',
            defaultMessage:
              '<li><b>Expanding your treatment repertoire</b></li>\n<li><b>Export and template of data on treatment progress</b> by your patients</li>\n<li><b>No additional effort</b>, as patients go through the therapy independently</li>\n<li><b>Budget-neutral</b> and without additional payments</li>',
          }),
          color: '#DAECEB',
        },
      ],
    },
    tracking: {
      title: 'How <b>Cara Care for IBS</b> works',
      description:
        'Step by step to significant symptom reduction and improved quality of life.',
      sliderTitle:
        'Our supportive <br/><span style="color: #489F9D">app features</span>',
      items: [
        {
          prefix: 'Tracking & Diary',
          title: 'Capture of diet, symptoms, and well-being.',
          description:
            'Patients can record their diet, symptoms, and feelings, thus recognizing correlations and trigger factors.',
          phoneImage: Tracking,
          bgImage: BgImage1,
        },
        {
          prefix: 'Individual support',
          title: 'Personalized program',
          description:
            'A program tailored to your patients following a program that is precisely aligned with their symptoms.',
          phoneImage: Program,
          bgImage: BgImage2,
        },
        {
          prefix: 'Audio-guided hypnosis',
          title: 'Gut-brain axis',
          description:
            'Audio-guided hypnosis, tasks, and quizzes - patients learn how the gut and the brain interact.',
          phoneImage: Hypnosis,
          bgImage: BgImage3,
        },
      ],
      ctaTitle: 'Curious?',
      ctaDescription:
        'Find more details on our product page for Cara Care for IBS.',
      primaryCTAButtonText: 'Learn more',
      primaryCTAButtonLink: '/reizdarm',
    },
    qualities: {
      prefix: 'Quality',
      title: 'We meet the highest<br/>standards for digital therapy.',
      items: [
        {
          icon: <DataSecurity />,
          title: 'Strictest data protection',
          description: 'GDPR-compliant & encrypted health data',
        },
        {
          icon: <Experts />,
          title: 'Scientifically based',
          description: 'Developed by experts',
        },
        {
          icon: <MedicalDevice />,
          title: 'Official medical product',
          description: 'Medical product of risk class 1 according to MDD',
        },
        {
          icon: <Science />,
          title: 'Medically up-to-date',
          description: 'Based on the latest medical guidelines',
        },
      ],
    },
    howToPrescribe: {
      prefix: 'Prescribing Cara Care for IBS',
      title: 'How is Cara Care for<br/>IBS prescribed?',
      items: [
        {
          icon: Prescription3,
          number: '1.',
          description:
            'Issue a prescription to your patients. Budget-neutral and without additional payments.',
        },
        {
          icon: Prescription1,
          number: '2.',
          description:
            'For the prescription, the diagnosis of IBS (ICD-10-GM: K58, K58.1, K58.2, K58.3, K58.8) must be present.',
        },
        {
          icon: Prescription2,
          number: '3.',
          description: (
            <EmbedAction
              prefix="Use "
              suffix=" and search for <b>“CARACARE”</b> using the <b>PZN 17964363</b>"
              action={toggleOverlay}
              text="Sample 16 (Health Insurance Prescription)"
            />
          ),
        },
        {
          icon: Prescription4,
          number: '4.',
          description:
            'We support your patients in obtaining the activation code and with all technical questions',
        },
      ],
      primaryCTAButtonText: 'Request informational material',
    },
    webinare: {
      title: 'Our <b>Webinars</b>',
      description:
        'In these 45-minute online trainings, you will gain insights into Cara Care for IBS, the underlying approaches and evidence. You can ask questions and interact with our experts and your professional colleagues.',
      items: [
        {
          title: 'Live Webinar',
          description: '',
          primaryCTAButtonText: 'Show agenda',
          primaryCTAButtonLink: LIVE_STORM_LINK,
        },
        {
          title: 'Recorded Webinars',
          description: '',
          primaryCTAButtonText: 'Get access',
          primaryCTAButtonLink: WEBINAR_LINK,
        },
      ],
    },
    testimonials: {
      prefix: 'Board',
      title: 'Our scientific leadership',
      items: [
        {
          image: <></>,
          title: 'Prof. Anthony Lembo',
          description:
            "Physician and Professor of Medicine at Harvard Medical School • Member of Cara Care's scientific advisory board",
        },
        {
          image: <></>,
          title: 'Prof. Dr. med. Stefan Lüth',
          description:
            'Clinic Director and Chief Physician at Klinikum Brandenburg • University Coordinator at the Medical University of Brandenburg',
        },
        {
          image: <></>,
          title: 'Dr. med. Andre Sommer',
          description: 'Co-founder of Cara Care',
        },
        {
          image: <></>,
          title: 'Linda Weißer',
          description: 'Medical Director',
        },
      ],
    },
    lastCTA: {
      title: 'Any questions left?',
      description:
        'We are here to help clarify any uncertainties during a 10-minute information session.',
      primaryCTAButtonText: 'Book a 10-minute information session',
    },
  };

  const strings = locale === 'de' ? deStrings : enStrings;

  return (
    <>
      {showImageOverlay && (
        <ImageOverlay
          image={PrescriptionPreviewImage}
          onClose={toggleOverlay}
        />
      )}
      <SEO lang={locale} title={doctors.seoTitle} />
      <FeatureHeader
        prefix={strings.header.prefix}
        title={strings.header.title}
        items={strings.header.items}
        primaryCTAButtonText={strings.header.primaryCTAButtonText}
        secondaryCTAButtonText={strings.header.secondaryCTAButtonText}
        secondaryCTAButtonLink={strings.header.secondaryCTAButtonLink}
      />
      <StudySection content={strings.study} />
      <Benefits
        title={strings.benefits.title}
        text={strings.benefits.description}
        items={[
          {
            image: docBenefits,
            heading: intl.formatMessage({
              id: 'benefits.column.one.content.header',
              defaultMessage: 'Vorteile für Ihre Patient*innen',
            }),
            content: intl.formatMessage({
              id: 'benefits.column.one.content',
              defaultMessage:
                '<li><b>Multimodale Reizdarm-Therapie</b>, konform mit der aktuellen S3-Leitlinie</li>\n<li><b>Personalisierte Modulzusammenstellung</b> basierend auf medizinischen Fragebögen</li>\n<li><b>zusätzliche Features</b>: Tracking und personalisierte Kochrezeptideen</li>\n<li><b>Linderung psychischer Komorbiditäten</b> (Angst, Depression), die im Praxisalltag oft nicht adressiert werden können</li>\n<li>Unterstützung bei der <b>Bewältigung krankheitsbedingter Schwierigkeiten im Alltag</b></li>\n<li><b>Vollständig erstattungsfähig</b>, ohne zusätzliche Kosten</li>',
            }),
            color: '#EAE3F3',
          },
          {
            image: patientBenefits,
            heading: intl.formatMessage({
              id: 'benefits.column.two.content.header',
              defaultMessage: 'Ihre Vorteile',
            }),
            content: intl.formatMessage({
              id: 'benefits.column.two.content',
              defaultMessage:
                '<li><b>Erweiterung Ihres Behandlungs-Repertoires</b></li>\n<li><b>Export und Vorlage von Daten zum Therapiefortschritt</b> durch Ihre Patient*innen</li>\n<li><b>Kein Mehraufwand</b>, da Patient*innen die Therapie selbstständig durchlaufen</li>\n<li><b>Budgetneutral</b> und ohne Zuzahlung</li>',
            }),
            color: '#DAECEB',
          },
        ]}
      />
      <NewTracking
        title={strings.tracking.title}
        description={strings.tracking.description}
        sliderTitle={strings.tracking.sliderTitle}
        dataObj={strings.tracking.items}
        ctaTitle={strings.tracking.ctaTitle}
        ctaDescription={strings.tracking.ctaDescription}
        primaryCTAButtonText={strings.tracking.primaryCTAButtonText}
        primaryCTAButtonLink={strings.tracking.primaryCTAButtonLink}
      />
      <Qualities
        prefix={strings.qualities.prefix}
        title={strings.qualities.title}
        items={strings.qualities.items}
      />
      <HowToPrescribe
        prefix={strings.howToPrescribe.prefix}
        title={strings.howToPrescribe.title}
        items={strings.howToPrescribe.items}
        primaryCTAButtonText={strings.howToPrescribe.primaryCTAButtonText}
      />
      <Webinare webinare={strings.webinare} />
      <TestimonialsSection
        header={doctors.testimonialsHeader}
        testimonials={doctors.testimonialsList}
      />
      <FAQ activeColor="#EDF5F5" hideBottomButton />
      <CallToActionSection
        callToAction={{
          button: (
            <HubspotMeetingOverlay
              meetingLink={HUBSPOT_MEETING_IBRAHIM_LINK || ''}
              buttonText={strings.lastCTA.primaryCTAButtonText}
              style={{
                color: '#fff',
                fontWeight: 700,
                borderColor: '#489F9D',
                marginBottom: 20,
                width: '100%',
                backgroundColor: '#489F9D',
                marginLeft: 0,
                padding: '1rem 2.5rem',
              }}
            />
          ),
          text: strings.lastCTA.description,
          title: strings.lastCTA.title,
        }}
      />
    </>
  );
};

export const doctorsPageQuery = graphql`
  query doctorsPage($locale: String!) {
    doctors: contentfulDoctorsPage(node_locale: { eq: $locale }) {
      seoTitle
      topSection {
        heading
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        image {
          fluid(maxWidth: 704) {
            ...GatsbyContentfulFluid
          }
        }
      }
      whatIsCaraCare {
        heading
        prefix
        shortText
      }
      whatIsCaraCareBoxes {
        heading
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      carePrinciples {
        prefix
        shortText
        heading
        image {
          fluid(maxWidth: 310) {
            ...GatsbyContentfulFluid
          }
        }
      }
      carePrinciplesButtonLink {
        text
        url
      }
      carePrinciplesList {
        image {
          file {
            fileName
            url
          }
        }
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }

      prescribeHeader {
        prefix
        heading
        shortText
      }
      prescribeCards {
        heading
        shortText
        image {
          title
          file {
            url
          }
        }
      }
      flyersHeader {
        prefix
        shortText
        heading
      }
      flyersButtons {
        url
        text
      }
      flyersAgreementText {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      outcomesHeader {
        shortText
        prefix
        heading
      }
      outcomesBoxes {
        heading
        shortText
        image {
          title
          file {
            url
          }
        }
      }
      outcomesCtaButton {
        url
        text
      }
      advantagesHeader {
        prefix
        heading
        shortText
      }
      advantagesCards {
        heading
        shortText
        image {
          title
          file {
            url
          }
        }
      }
      safetyHeader {
        prefix
        shortText
        heading
      }
      safetyItemsList {
        text {
          text
        }
        image {
          title
          fixed(width: 250) {
            ...GatsbyContentfulFixed
          }
        }
      }
      testimonialsHeader {
        shortText
        heading
        prefix
      }
      testimonialsList {
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
      whatYouNeedToKnowHeader {
        prefix
        heading
        shortText
      }
      whatYouNeedToKnowItems {
        heading
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      referencesBlock {
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        heading
      }
      referencesCollapsedMessage
      featureHeader {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        callToAction {
          text
          url
          isDisplayed
        }
        items {
          id
          heading
        }
      }
      trackingSection {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        items {
          id
          prefix
          heading
          shortText
          image {
            fluid(maxWidth: 275) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default withLayout(Doctors, { isNewLayout: true, isDarkHeader: false });
