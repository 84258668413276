import React, { FC } from 'react';
import CloseCross from '../assets/images/close-cross.png';
import Button from './common/Button';

interface Props {
  meetingLink: string;
  buttonText: string;
  style?: React.CSSProperties;
}

const openHubspotOverlay = (meetingLink: string) => {
  const hubspotOverlay = document.querySelector('.hubspot-overlay');
  const hubspotOverlayContent = document.querySelector(
    '.hubspot-overlay-content'
  );
  const hubspotOverlayClose = document.querySelector('.hubspot-overlay-close');

  hubspotOverlayClose.addEventListener('click', () => {
    hubspotOverlay.classList.remove('hubspot-overlay--open');
  });

  hubspotOverlay.addEventListener('click', (event) => {
    if (event.target === hubspotOverlay) {
      hubspotOverlay.classList.remove('hubspot-overlay--open');
    }
  });

  hubspotOverlay.classList.add('hubspot-overlay--open');
  hubspotOverlayContent.innerHTML = `
    <iframe
      src="${meetingLink}"
      width="100%"
      height="100%"
      frameborder="0"
      style="border: 0"
      allowfullscreen
    ></iframe>

    <style>
      .hubspot-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 9999;
        display: none;
        justify-content: center;
        align-items: center;
      }

      .hubspot-overlay--open {
        display: flex;
      }
    
      .hubspot-overlay-content {
        position: relative;
        width: 100%;
        height: 100%;
      }
    
      .hubspot-overlay-content iframe {
        width: 100%;
        height: 100%;
      }

      .hubspot-overlay-close {
        position: absolute;
        top: 40px;
        right: 20px;
        width: 30px;
        height: 30px;
        background-image: url(${CloseCross});
        background-size: cover;
        cursor: pointer;
        z-index: 9999;
      }

      @media (min-width: 768px) {
        .hubspot-overlay-content {
          max-width: 800px;
          max-height: calc(100vh - 40px);
        }
      }

      @media (min-width: 1000px) {
        .hubspot-overlay-close {
          display: none;
        }
      }
    </style>
  `;
};

const HubspotMeetingOverlay: FC<Props> = ({
  meetingLink,
  buttonText,
  style,
}) => {
  return (
    <>
      <Button
        design="bordered"
        onClick={() => openHubspotOverlay(meetingLink)}
        css={{
          color: '#fff',
          fontWeight: 700,
          borderColor: '#fff',
          marginLeft: 20,
          padding: '0.5rem 3.5rem',
          marginBottom: 20,
        }}
        style={style}
      >
        {buttonText}
      </Button>
    </>
  );
};

export default HubspotMeetingOverlay;
