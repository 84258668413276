import React, { FC, ReactNode, useCallback, useEffect } from 'react';
import { RecoletaHeading } from '../common/typography';
import CollapsingItemList from './CollapsingItemList';
import Container from '../Homepage/Container';
import { useIntl } from 'react-intl';
import { HUBSPOT_MEETING_MANU_LINK } from '../../utils/constants';
import HubspotMeetingOverlay from '../HubspotMeetingOverlay';

export type FaqItem = {
  heading: string;
  text: string;
  button?: ReactNode;
};

interface Props {
  hideBottomButton?: boolean;
  activeColor?: string;
}

const FAQ: FC<Props> = ({ hideBottomButton, activeColor }) => {
  const intl = useIntl();

  const renderButton = useCallback(() => {
    return (
      <HubspotMeetingOverlay
        meetingLink={HUBSPOT_MEETING_MANU_LINK || ''}
        buttonText={intl.formatMessage({
          id: 'homepage.v2.feature.next.steps.cta.button',
          defaultMessage: 'Gespräch Buchen',
        })}
        style={{
          color: '#9775C1',
          fontWeight: 700,
          borderColor: '#9775C1',
          marginBottom: 20,
          width: '100%',
          backgroundColor: 'transparent',
          marginLeft: 0,
        }}
      />
    );
  }, [intl]);

  const germanFAQ: FaqItem[] = [
    {
      heading: 'Wann ist Cara Care für meine Probleme geeignet?',
      text: `Cara Care für Reizdarm ist ein digitales Therapieprogramm für Reizdarm-Patient*innen, die ihre Bauchbeschwerden selbst in die Hand nehmen wollen.
        \n\nHast du ein ärztlich bestätigtes Reizdarmsyndrom, bist zwischen 18 und 70 Jahre alt und nicht schwanger, dann ist Cara Care für Reizdarm genau das Richtige für dich.
        \n\nHast du den Verdacht, dass bei dir ein Reizdarm vorliegt, wende dich an deine Ärztin bzw. deinen Arzt.`,
    },
    {
      heading: 'Für wen ist <b>Cara Care für Reizdarm</b> nicht geeignet?',
      text: `Cara Care für Reizdarm darf nicht verwendet werden, wenn:
  - du jünger als 18 Jahre oder älter als 70 Jahre bist,
  - bei dir eine Schwangerschaft vorliegt,
  - du kein ärztlich bestätigtes Reizdarmsyndrom hast. 
  `,
    },
    {
      heading: 'Wie erhalte ich Zugang?',
      text: `Die App Cara Care kannst du dir ganz einfach im deutschen App Store bzw. Play Store herunterladen. Für die Accounterstellung und den Zugang zu Cara Care für Reizdarm benötigst du jedoch einen sogenannten Freischaltcode deiner gesetzlichen Krankenkasse.
      \n\nDiesen kannst du mithilfe einer ärztlichen Verschreibung bzw. eines Diagnosenachweises bei deiner gesetzlichen Krankenkasse beantragen.
      \n\nWie das funktioniert, erfährst du hier
      \n\nDu bist privat versichert? Dann melde dich einfach direkt bei uns.
      `,
    },
    {
      heading: 'Ist der Zugang zur App zeitlich begrenzt?',
      text: `Die Nutzungsdauer von Cara Care für Reizdarm beträgt 90 Tage ab Aktivierung des Codes in der App. Das bedeutet: Dein Nutzungszeitraum läuft, sobald du den Code in der App eingegeben hast.
      \n\nMöchtest du Cara Care für Reizdarm nach Ablauf dieser 90 Tage weiterhin nutzen, benötigst du einen neuen Zugangscode von deiner Krankenkasse.
      `,
    },
    {
      heading: 'Was ist in der DIGA enthalten?',
      text: `Cara Care für Reizdarm ist ein digitales Therapieprogramm für Reizdarm-Patient*innen.
      \n\nDu wendest Cara Care für Reizdarm als Patient*in selber an, um deine funktionellen Verdauungsbeschwerden zu reduzieren.
      \n\nIn der DiGA enthalten sind:\n\n
  1. Ein personalisiertes 12-Wochen-Programm, in dem therapeutische Inhalte aus den Bereichen Ernährung, mentale Gesundheit sowie Reizdarm-Wissen vermittelt werden. 
  2. Ein Ernährungs- und Symptomtagebuch zur einfachen Erfassung und eigenständigen Auswertung deiner Symptome sowie Einflussfaktoren wie Ernährung und Stress. 
  3. Auf dich zugeschnittene darmfreundliche Rezepte.
      `,
    },
    {
      heading: 'Kann ich alle Funktionen/Programminhalte nutzen?',
      text: `Jeder Reizdarm ist individuell. Deshalb passen wir die Programminhalte und Therapieempfehlungen an deine persönlichen Bedürfnisse an.
      \n\nInsgesamt bieten wir 4 Module als Programminhalte an:\n\n
  - Basiswissen
  - Ernährung
  - Gefühle und Verhalten
  - Audio-geführte Hypnose
  \n\n Welche Module dir angezeigt werden, wird anhand eines Gesundheits-Fragebogens und daraus abgeleiteten Kontraindikationen zu Beginn des Programms bestimmt. Je nach Profil erhältst du Zugriff auf 1 - 3 Module.
  \n\nDas Ernährungs- und Symptomtagebuch sowie die Rezepte stehen dir über die gesamte Nutzungsdauer unabhängig von der Modulzuweisung zur Verfügung.
      `,
    },
    {
      heading: 'Erhalte ich eine personalisierte Beratung?',
      text: `Bei Cara Care für Reizdarm handelt es sich um ein rein digitales Therapiekonzept, das von dir selbst angewendet wird.
      \n\nSelbstverständlich versuchen wir dein Programm so gut wie möglich an deine Bedürfnisse anzupassen und dir entsprechende Inhalte anzuzeigen. Eine persönliche Beratung bzw. Auswertung des Ernährungs- und Symptomtagebuchs durch Fachkräfte ist jedoch nicht in der DiGA enthalten.
      `,
    },
    {
      heading: 'Kann ich mich bei Fragen an Cara Care wenden?',
      text: `Hast du Fragen zu Cara Care für Reizdarm, technische Probleme oder benötigst Hilfestellungen bei der Nutzung der App, kannst du dich gerne an unseren technischen Kundensupport wenden. Dabei hast du folgende Möglichkeiten:\n\n
  1. Schreibe uns direkt in der App über den Chat.
  2. Schreibe uns eine E-Mail an hallo@cara.care.
  3. Rufe uns an unter +49 30 308 095 46.
  \n\nWichtig: Aus rechtlichen Gründen dürfen wir keine medizinische Beratung durchführen, sondern stehen ausschließlich für allgemeine Fragen rund um die App-Nutzung zur Verfügung. 
      `,
    },
    {
      heading:
        'Wie viel Zeit muss ich mir für <b>Cara Care für Reizdarm</b> nehmen?',
      text: `Die Frage lässt sich nicht so leicht beantworten. Tatsächlich ist der Zeitaufwand stark davon abhängig, welche und wie viele Module dir freigeschaltet wurden.\n\n
  - Das Hypnose-Modul beinhaltet z.B. täglich eine etwa 30-minütige Hypnose-Einheit. 
  - Die Module Ernährung sowie Gefühle & Verhalten erfordern nicht ganz so viel aktive Zeit in der App, jedoch mehr Zeit für die Umsetzung im Alltag. 
  - Für das Führen des Symptom- und Ernährungstagebuchs werden täglich etwa 5-10 min benötigt. 
  \n\nMit 2 - 3 Stunden pro Woche solltest du also auf jeden Fall rechnen. Vielleicht kommt dir das jetzt erstmal viel vor, aber siehe es mal so: Mit Cara Care für Reizdarm nimmst du dir täglich um die 30 min Zeit für dich und deinen Darm. 
      `,
    },
    {
      heading: 'Werden auch andere Erkrankungen berücksichtigt?',
      text: `Cara Care für Reizdarm ist rein auf die Therapie des Reizdarmsyndroms ausgelegt.
      \n\nDas bedeutet, dass unser 12-Wochen-Programm keine therapeutische Empfehlung für andere Erkrankungen beinhaltet. Jedoch berücksichtigen wir in unserem Ernährungsmodul bestimmte Lebensmittelallergien sowie Intoleranzen.
      `,
    },
  ];

  const englishFaq: FaqItem[] = [
    {
      heading: 'When is Cara Care suitable for my problems?',
      text: `Cara Care for IBS (IBS) is a digital therapy program for IBS patients who want to take control of their abdominal complaints.
      \n\nIf you have a medically confirmed diagnosis of IBS, are between 18 and 70 years old, and not pregnant, then Cara Care for IBS is the right choice for you.
      \n\nIf you suspect you have IBS, please consult your doctor.`,
    },
    {
      heading: 'Who is <b>Cara Care for IBS</b> not suitable for?',
      text: `Cara Care for IBS must not be used if:
  - You are younger than 18 years or older than 70 years,
  - You are pregnant,
  - You do not have a medically confirmed diagnosis of IBS.`,
    },
    {
      heading: 'How do I get access?',
      text: `You can easily download the Cara Care app from the German App Store or Play Store. However, to create an account and access Cara Care for IBS, you will need an unlock code from your statutory health insurance.
      \n\nYou can obtain this code by submitting a medical prescription or a diagnosis confirmation to your statutory health insurance.
      \n\nFind out how it works here
      \n\nAre you privately insured? Then simply contact us directly.
      `,
    },
    {
      heading: 'Is the access to the app limited in time?',
      text: `The usage period of Cara Care for IBS is 90 days from the activation of the code in the app. This means your usage period begins as soon as you enter the code in the app.
      \n\nIf you wish to continue using Cara Care for IBS after these 90 days, you will need a new access code from your health insurance.
      `,
    },
    {
      heading: 'What is included in the digital therapeutic program (DiGA)?',
      text: `Cara Care for IBS is a digital therapy program for IBS patients.
      \n\nYou, as a patient, use Cara Care for IBS to reduce your functional digestive complaints.
      \n\nThe DiGA includes:\n\n
  1. A personalized 12-week program that provides therapeutic content in the areas of nutrition, mental health, and IBS knowledge.
  2. A nutrition and symptom diary for easy recording and independent evaluation of your symptoms, as well as influencing factors such as diet and stress.
  3. Customized gut-friendly recipes.
      `,
    },
    {
      heading: 'Can I use all functions/program content?',
      text: `Every IBS is individual. Therefore, we tailor the program content and therapy recommendations to your personal needs.
      \n\nOverall, we offer 4 modules as program content:\n\n
  - Basic knowledge
  - Nutrition
  - Emotions and behavior
  - Audio-guided hypnosis
  \n\n The modules you will see are determined based on a health questionnaire and derived contraindications at the beginning of the program. Depending on your profile, you will have access to 1 - 3 modules.
  \n\nThe nutrition and symptom diary as well as the recipes are available to you throughout the usage period regardless of the module assignment.
      `,
    },
    {
      heading: 'Will I receive personalized counseling?',
      text: `Cara Care for IBS is a purely digital therapy concept that you apply yourself.
      \n\nOf course, we try to adapt your program to your needs as much as possible and show you relevant content. However, personalized counseling or evaluation of the nutrition and symptom diary by healthcare professionals is not included in the DiGA.
      `,
    },
    {
      heading: 'Can I contact Cara Care if I have questions?',
      text: `If you have questions about Cara Care for IBS, technical issues, or need assistance using the app, you can contact our technical customer support. You have the following options:\n\n
  1. Write to us directly in the app via chat.
  2. Send us an email at hallo@cara.care.
  3. Call us at +49 30 308 095 46.
  \n\nImportant: For legal reasons, we are not allowed to provide medical advice, but we are available exclusively for general questions about app usage.
      `,
    },
    {
      heading:
        'How much time do I need to dedicate to <b>Cara Care for IBS</b>?',
      text: `The question is not so easy to answer. The time required depends on which and how many modules have been unlocked for you.\n\n
  - The hypnosis module, for example, includes a daily 30-minute hypnosis session.
  - The Nutrition and Emotions & Behavior modules do not require as much active time in the app but more time for implementation in everyday life.
  - Daily, about 5-10 minutes are needed for maintaining the symptom and nutrition diary.
  \n\nYou should expect about 2 - 3 hours per week. It might seem like a lot at first, but think of it this way: With Cara Care for IBS, you dedicate around 30 minutes per day to yourself and your gut.
      `,
    },
    {
      heading: 'Are other conditions also considered?',
      text: `Cara Care for IBS is focused solely on the therapy of IBS.
      \n\nThis means that our 12-week program does not include therapeutic recommendations for other conditions. However, in our nutrition module, we consider certain food allergies and intolerances.
      `,
    },
  ];

  useEffect(() => {
    if (!hideBottomButton && intl.locale === 'en') {
      englishFaq.push({
        heading: 'Do you have unanswered questions?',
        text: `Then simply schedule an informational conversation with us. We are always happy to help!
      `,
        button: renderButton(),
      });
    }

    if (!hideBottomButton && intl.locale === 'de') {
      germanFAQ.push({
        heading: 'Du hast noch unbeantwortete Fragen?',
        text: `Dann vereinbare einfach ein Infogespräch mit uns. Wir helfen dir jederzeit gern!
      `,
        button: renderButton(),
      });
    }
  }, [englishFaq, germanFAQ, hideBottomButton, intl.locale, renderButton]);

  return (
    <Container
      css={{
        maxWidth: 900,
        marginTop: '5rem',
        marginBottom: '5rem',
      }}
    >
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '2rem',
        }}
      >
        <RecoletaHeading
          css={{
            fontSize: '38px',
            fontWeighwt: 500,
            lineHeight: '48px',
          }}
        >
          {intl.formatMessage({
            id: 'product.page.faq.title',
            defaultMessage: 'Häufige Fragen',
          })}
        </RecoletaHeading>
      </div>

      <CollapsingItemList
        boxes={intl.locale === 'de' ? germanFAQ : englishFaq}
        activeColor={activeColor ? activeColor : '#9775C126'}
        opacity={0.5}
      />
    </Container>
  );
};

export default FAQ;
