import styled from '@emotion/styled';
import hexToRgba from 'hex-to-rgba';
import React, { useState } from 'react';
import presets from '../../styles/presets';
import ArrowIcon from '../../assets/images/arrow-icon.svg';
import { ContentfulHeadingAndText } from '../../graphql-types';
import { OpenSansParagraph, RecoletaHeading } from './typography';
import MarkdownRenderer from './MarkdownRenderer';

interface ArrowImageProps {
  isActive: boolean;
}
interface ItemBoxProps {
  isActive: boolean;
  index: number;
  currentIndex: number;
  activeColor: string;
  opacity: number;
}

const ItemBox = styled.div<ItemBoxProps>`
  transition: 0.3s ease-in-out;
  padding: ${({ isActive }) =>
    isActive ? `24px 19px 24px 19px` : `0 19px 0 19px`};
  ${presets.md} {
    padding: ${({ isActive }) =>
      isActive ? `50px 25px 45px 32px` : `0 25px 0 32px`};
  }
  min-height: ${({ isActive }) => (isActive ? 180 : 80)}px;
  background-color: ${({ isActive, activeColor, opacity }) =>
    isActive ? hexToRgba(activeColor, opacity) : 'none'};
  border-radius: ${({ isActive }) => (isActive ? 10 : 0)}px;
  ${({ isActive, index, currentIndex }) =>
    !isActive &&
    `
    border-bottom: ${
      currentIndex - index !== 1 && `0.2px solid ${hexToRgba('#160B2C', 0.2)}`
    } ;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  `}
`;

const ArrowImage = styled.img<ArrowImageProps>`
  margin-bottom: 0;
  margin-left: 7px;
  transition: 0.25s ease-in-out;
  max-width: 16px;
  transform: ${({ isActive }) => (isActive ? 'rotate(180deg)' : 'rotate(0)')};
  &:hover {
    cursor: pointer;
  }
`;

interface Props {
  boxes: ContentfulHeadingAndText[];
  activeColor: string;
  opacity: number;
}

const CollapsingItemList: React.FC<Props> = ({
  boxes,
  activeColor,
  opacity,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div>
      {boxes.map((el, index) => (
        <ItemBox
          key={el.heading}
          activeColor={activeColor}
          opacity={opacity}
          isActive={activeIndex === index}
          index={index}
          currentIndex={activeIndex}
          onClick={() => {
            setActiveIndex(index);
          }}
        >
          {activeIndex === index ? (
            <>
              <div
                css={{
                  display: 'flex',
                  alignItems: 'baseline',
                  justifyContent: 'space-between',
                  minHeight: 40,
                }}
              >
                <RecoletaHeading>{el.heading}</RecoletaHeading>
                <div css={{ display: 'flex', userSelect: 'none' }}>
                  <ArrowImage
                    src={ArrowIcon}
                    alt="Arrow"
                    isActive={activeIndex === index}
                  />
                </div>
              </div>
              <div css={{ maxWidth: 400, marginTop: 20 }}>
                <OpenSansParagraph>
                  <MarkdownRenderer {...el.text.childMarkdownRemark} />
                </OpenSansParagraph>
              </div>
            </>
          ) : (
            <>
              <div
                css={{
                  padding: '10px 0',
                  [presets.lg]: { padding: 0 },
                  cursor: 'pointer',
                }}
              >
                <RecoletaHeading>{el.heading}</RecoletaHeading>
              </div>
              <div css={{ display: 'flex', userSelect: 'none' }}>
                <ArrowImage
                  src={ArrowIcon}
                  alt="Arrow"
                  isActive={activeIndex === index}
                />
              </div>
            </>
          )}
        </ItemBox>
      ))}
    </div>
  );
};

export default CollapsingItemList;
