import styled from '@emotion/styled';
import { ContainerProps } from 'emotion-flex';
import React, { FC, ReactNode } from 'react';
import {
  MonoFontLabel,
  OpenSansParagraph,
  RecoletaHeading,
} from '../common/typography';
import { markdownText } from '../../pages/doctors';

const Container = styled('div')(
  {
    paddingLeft: 24,
    paddingRight: 24,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '3rem',
    marginBottom: '5rem',
  },
  ({ fluid }: ContainerProps) => ({
    maxWidth: fluid ? '100%' : '900px',
  })
);

interface ItemProps {
  icon: ReactNode;
  title: string;
  description: string;
}

interface HeroNewParagraphProps {
  prefix: string;
  title: string;
  items: ItemProps[];
}

const Qualities: FC<HeroNewParagraphProps> = ({ prefix, title, items }) => {
  return (
    <Container>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '2em auto',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <MonoFontLabel
          css={{
            fontWeight: 700,
          }}
        >
          {prefix}
        </MonoFontLabel>
        <RecoletaHeading
          css={{
            fontSize: 32,
            fontWeight: 500,
          }}
        >
          {markdownText(title)}
        </RecoletaHeading>
      </div>

      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          margin: '0 auto',
          padding: '0 1.5rem',
          width: '100%',
          textAlign: 'center',

          // Add this media query for mobile devices
          '@media (max-width: 768px)': {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
            gridGap: '20px',
          },
        }}
      >
        {items.map(({ icon, title, description }) => (
          <div
            key={title}
            css={{
              width: '22%',
              '@media (max-width: 768px)': {
                width: '100%',
              },
            }}
          >
            {icon}
            <OpenSansParagraph
              css={{
                fontSize: 18,
                fontWeight: 700,
                opacity: 1,
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              {title}
            </OpenSansParagraph>
            <OpenSansParagraph
              css={{
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              {description}
            </OpenSansParagraph>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Qualities;
