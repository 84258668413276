import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import {
  ContentfulImageAndText,
  ContentfulImageHeadingAndShortText,
} from '../../graphql-types';
import { ContentWrapper, FullWidthSection } from '../common/layout';
import TripleTitle from '../common/TripleTitle';
import SafetyTick from '../../assets/images/doctors/safety_tick.svg';
import { RecoletaHeading } from '../common/typography';
import Img from 'gatsby-image/withIEPolyfill';
import presets from '../../styles/presets';
import BackgroundImage from '../../assets/images/doctors/safety_background.svg';

interface Props {
  header: ContentfulImageHeadingAndShortText;
  itemsList: ContentfulImageAndText[];
}

const sectionStyle = css`
  background: url(${BackgroundImage});
  background-size: auto 100%;
  background-position: 50% 0;
  background-repeat: no-repeat;
  margin-top: 50px;
  padding-top: 100px;
  padding-bottom: 100px;

  ${presets.xl} {
    background-size: 200% 100%;
    background-clip: border-box;
    padding-top: 120px;
    margin-top: 100px;
    margin-bottom: 100px;
  }
`;

const wrapperStyle = css`
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
`;

const SafetyListContainer = styled.div(css`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;

  ${presets.md} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-left: 60px;
    padding-right: 60px;
  }
`);

const ListItem = styled.div(css`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  ${presets.md} {
    flex-basis: 50%;
  }
`);

const itemHeadingStyle = css`
  font-size: 18px;
  line-height: 30px;
  padding-left: 13px;
`;

const itemImageStyle = css`
  max-width: 250px;
  object-fit: contain;
  align-self: center;
  margin-top: 10px;

  ${presets.md} {
    align-self: flex-start;
    margin-left: 40px;
    margin-top: 0px;
  }
`;

const Safety: React.FC<Props> = ({ header, itemsList }) => {
  return (
    <FullWidthSection css={sectionStyle}>
      <ContentWrapper css={wrapperStyle}>
        <TripleTitle
          headingText={header.heading}
          labelText={header.prefix}
          paragraphText={header.shortText}
        />
        <SafetyListContainer>
          {itemsList.map((item, ind) => (
            <ListItem key={ind}>
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  align-items: flex-start;
                `}
              >
                <img src={SafetyTick} alt="Tick" width="26px" />
                <RecoletaHeading css={itemHeadingStyle}>
                  {item.text.text}
                </RecoletaHeading>
              </div>
              {item.image && (
                <Img fixed={item.image.fixed} css={itemImageStyle} />
              )}
            </ListItem>
          ))}
        </SafetyListContainer>
      </ContentWrapper>
    </FullWidthSection>
  );
};

export default Safety;
