import { css } from '@emotion/core';
import React from 'react';
import {
  ContentfulImageHeadingAndShortText,
  ContentfulImageAndText,
} from '../../graphql-types';
import { ReactComponent as TopWave } from '../../assets/svgs/doctors/advisorsTop.svg';
import { ReactComponent as BottomWave } from '../../assets/svgs/doctors/advisorsBottom.svg';
import DotsTestimonial from '../common/sections/DotsTestimonial';

interface Props {
  header: ContentfulImageHeadingAndShortText;
  testimonials: ContentfulImageAndText[];
}

const sectionStyle = css`
  padding: 30px 0;
  background-color: #3d72df1a;
  text-align: center;
  cursor: grab;

  .slick-next:before,
  .slick-prev:before {
    content: '';
  }

  div:focus {
    outline: none;
  }
`;

const TestimonialsSection: React.FC<Props> = ({ header, testimonials }) => {
  return (
    <>
      <TopWave
        width="100vw"
        height="auto"
        css={{
          display: 'block',
          width: '100%',
          height: 'auto',
          marginTop: 70,
        }}
      />
      <div css={sectionStyle}>
        <DotsTestimonial header={header} testimonials={testimonials} />
      </div>
      <BottomWave
        width="100%"
        height="83"
        css={{ width: '100%', height: 'auto', marginBottom: 30 }}
      />
    </>
  );
};

export default TestimonialsSection;
