import React, { FC } from 'react';
import { SYSTEM_FONTS } from '../../styles/typography';

interface Props {
  image: string;
  onClose: () => void;
}

const ImageOverlay: FC<Props> = ({ image, onClose }) => {
  return (
    <div
      onClick={onClose}
      css={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <div
        css={{
          maxWidth: '50%',
          maxHeight: '100%',
          margin: 'auto',
          '@media (max-width: 768px)': {
            maxWidth: '90%',
          },
        }}
      >
        <img
          src={image}
          alt="Display"
          style={{ width: '100%', height: 'auto' }}
        />
      </div>
      <button
        css={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          padding: '10px 20px',
          backgroundColor: 'white',
          border: 'none',
          cursor: 'pointer',
          borderRadius: '10px',
          fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
          fontWeight: 600,
        }}
        onClick={onClose}
      >
        Close
      </button>
    </div>
  );
};

export default ImageOverlay;
