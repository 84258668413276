import styled from '@emotion/styled';

export const FullWidthSection = styled.div({
  width: '100%',
});

export const ContentWrapper = styled.div({
  maxWidth: '1440px',
  margin: '0 auto',
});

export const StartKitWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
});
