import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';

import {
  ContentfulImageHeadingAndShortText,
  ContentfulLink,
} from '../../graphql-types';
import presets from '../../styles/presets';
import { OpenSansParagraph, RecoletaHeading } from '../common/typography';
import { ButtonLink } from '../common/Button';
import { ContentWrapper } from '../common/layout';
import Video from '../Video';

interface Props {
  header: ContentfulImageHeadingAndShortText;
  outcomesBoxes: ContentfulImageHeadingAndShortText[];
  outcomesButton: ContentfulLink;
}

const wrapperStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

const HeadingContainer = styled.div(css`
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 500px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 30px;

  ${presets.md} {
    margin-bottom: 42px;
  }
`);

const BoxesContainer = styled.div(css`
  margin-left: 10px;
  margin-right: 10px;
`);

const OutcomesBox = styled.div(css`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: #d7e8e7;
  align-items: center;
  width: 100%;
  margin-top: 12px;
  padding: 30px;
  text-align: center;

  ${presets.md} {
    flex-direction: row;
    text-align: left;
    justify-content: space-between;
    width: 700px;
    height: 120px;
    margin-top: 16px;
    padding: 0 20px;
  }
`);

const outcomeHeadingStyle = css`
  font-size: 36px;
  line-height: 32px;
  margin-bottom: 18px;

  #percent {
    font-size: 24px;
  }

  ${presets.md} {
    width: 25%;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 0;

    #percent {
      font-size: 28px;
    }
  }

  ${presets.lg} {
    font-size: 44px;
    line-height: 58px;

    #percent {
      font-size: 32px;
    }
  }

  ${presets.xl} {
    font-size: 48px;
    line-height: 64px;

    #percent {
      font-size: 36px;
    }
  }
`;

const outcomeParagraphStyle = css`
  font-size: 16px;
  line-height: 24px;

  ${presets.md} {
    width: 50%;
    font-size: 18px;
    line-height: 26px;
  }
`;

const headingStyle = css({
  marginBottom: '7px',
  fontSize: '24px',
  lineHeight: '32px',
  fontWeight: 500,

  [presets.md]: {
    fontSize: '40px',
    lineHeight: '38px',
  },
  [presets.lg]: {
    fontSize: '40px',
    lineHeight: '44px',
  },
});

const paragraphStyle = css({
  fontSize: '14px',
  lineHeight: '20px',
  marginTop: '16px',

  [presets.md]: {
    fontSize: '16px',
    lineHeight: '23px',
  },
  [presets.lg]: {
    fontSize: '16px',
    lineHeight: '26px',
  },
});

const Outcomes: React.FC<Props> = ({
  header,
  outcomesBoxes,
  outcomesButton,
}) => {
  return (
    <ContentWrapper css={wrapperStyle}>
      <HeadingContainer>
        <RecoletaHeading css={headingStyle}>{header.heading}</RecoletaHeading>
        <OpenSansParagraph css={paragraphStyle}>
          {header.shortText}
        </OpenSansParagraph>
      </HeadingContainer>
      <BoxesContainer>
        {outcomesBoxes.map((ob, ind) => (
          <OutcomesBox key={ind}>
            <img
              src={ob.image.file.url}
              alt={ob.image.title}
              css={css`
                ${presets.md} {
                  margin-top: auto;
                  margin-bottom: auto;
                }
              `}
            />
            <RecoletaHeading css={outcomeHeadingStyle}>
              {ob.heading}
              <span id="percent">%</span>
            </RecoletaHeading>
            <OpenSansParagraph css={outcomeParagraphStyle}>
              {ob.shortText}
            </OpenSansParagraph>
          </OutcomesBox>
        ))}

        <div
          css={{
            maxWidth: 730,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 50,
          }}
        >
          <Video videoSrcURL={'https://www.youtube.com/embed/EoiSaE5SMTE'} />
        </div>
      </BoxesContainer>

      <ButtonLink
        to={outcomesButton?.url}
        css={css`
          margin-top: 20px;
          width: 300px;
          overflow: inherit;
        `}
      >
        {outcomesButton?.text}
      </ButtonLink>
    </ContentWrapper>
  );
};

export default Outcomes;
